import {
  IconArrow,
  IconCalendar,
  IconCancel,
  IconChat,
  IconChevronLeft,
  IconChevronRight,
  IconChevronDown,
  IconChevronUp,
  IconClose,
  IconCursor,
  IconDoc,
  IconDownload,
  IconEdit,
  IconError,
  IconInfo,
  IconLocation,
  IconMail,
  IconMenu,
  IconMobile,
  IconPercent,
  IconPhone,
  IconPlayer,
  IconPopup,
  IconPreview,
  IconPreviewOff,
  IconSettings,
  IconTools,
  IconUser,
  IconValid,
  IconHome,
  IconQuestionMark,
  IconSecureShield,
  IconBell,
  IconUnlock,
  IconPlus,
  IconStar,
  IconExclamationMark,
  IconExclamationMarkCircle,
  IconSearch,
  IconSafari,
  IconFileGreen,
  IconFileYellow,
  IconCalendar2,
  IconShare,
  IconTimer
} from '../';
import { IconType } from '../Icon.model';

export const getIconComponent = (icon: IconType) => {
  const components = {
    arrow: IconArrow,
    calendar: IconCalendar,
    cancel: IconCancel,
    chat: IconChat,
    chevronLeft: IconChevronLeft,
    chevronRight: IconChevronRight,
    chevronDown: IconChevronDown,
    chevronUp: IconChevronUp,
    close: IconClose,
    cursor: IconCursor,
    doc: IconDoc,
    edit: IconEdit,
    download: IconDownload,
    error: IconError,
    info: IconInfo,
    location: IconLocation,
    mail: IconMail,
    menu: IconMenu,
    mobile: IconMobile,
    percent: IconPercent,
    phone: IconPhone,
    player: IconPlayer,
    popup: IconPopup,
    preview: IconPreview,
    previewOff: IconPreviewOff,
    settings: IconSettings,
    tools: IconTools,
    user: IconUser,
    valid: IconValid,
    home: IconHome,
    questionMark: IconQuestionMark,
    secureShield: IconSecureShield,
    bell: IconBell,
    unlock: IconUnlock,
    plus: IconPlus,
    star: IconStar,
    exclamationMark: IconExclamationMark,
    exclamationMarkCircle: IconExclamationMarkCircle,
    search: IconSearch,
    safari: IconSafari,
    fileGreen: IconFileGreen,
    fileYellow: IconFileYellow,
    calendar2: IconCalendar2,
    share: IconShare,
    timer: IconTimer
  };

  return components[icon];
};
