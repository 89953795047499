import { SvgIcon } from '@mui/material';

const ExclamationMarkCircle = ({ ...props }) => (
  <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_11080_3602)">
      <path d="M8.00002 0C6.41776 0 4.87103 0.469215 3.55543 1.34827C2.23984 2.22732 1.21452 3.47673 0.609024 4.93854C0.00352281 6.40035 -0.15498 8.00888 0.153702 9.56073C0.462383 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.8875 15.5376 6.43935 15.8463C7.99119 16.1549 9.59973 15.9966 11.0615 15.3911C12.5233 14.7856 13.7727 13.7602 14.6517 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1572 3.84343 13.6569 2.34314C12.1566 0.842849 10.1217 0 8.00002 0ZM8.00002 14C6.81333 14 5.65334 13.6481 4.66664 12.9888C3.67995 12.3295 2.91081 11.3924 2.45668 10.2961C2.00255 9.19973 1.88374 7.99335 2.11525 6.82947C2.34676 5.66558 2.91822 4.5965 3.75734 3.75739C4.59645 2.91827 5.6656 2.34681 6.82948 2.1153C7.99337 1.88378 9.1998 2.0026 10.2962 2.45673C11.3925 2.91085 12.3295 3.67987 12.9888 4.66656C13.6481 5.65326 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2427 12.2426C11.1175 13.3678 9.59131 14 8.00002 14Z" />
      <path d="M7 8H9V4H7V8Z" />
      <path d="M7 11H9V9H7V11Z" />
    </g>
    <defs>
      <clipPath id="clip0_11080_3602">
        <path
          d="M0 0H16V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V0Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ExclamationMarkCircle;
