import { SvgIcon } from '@mui/material';

const Timer = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 16 17" fill="none">
    <path
      d="M8.05566 0.680298C3.70684 0.680298 0.180664 4.20647 0.180664 8.5553C0.180664 12.9041 3.70684 16.4303 8.05566 16.4303C12.4045 16.4303 15.9307 12.9041 15.9307 8.5553C15.9307 4.20647 12.4045 0.680298 8.05566 0.680298ZM8.05566 15.0944C4.44512 15.0944 1.5166 12.1658 1.5166 8.5553C1.5166 4.94475 4.44512 2.01624 8.05566 2.01624C11.6662 2.01624 14.5947 4.94475 14.5947 8.5553C14.5947 12.1658 11.6662 15.0944 8.05566 15.0944Z"
      fill="#FBB13C"
    />
    <path
      d="M11.1267 10.779L8.62006 8.96674V4.61615C8.62006 4.53881 8.55678 4.47552 8.47943 4.47552H7.63393C7.55658 4.47552 7.4933 4.53881 7.4933 4.61615V9.45717C7.4933 9.50287 7.5144 9.54506 7.55131 9.57142L10.4587 11.6913C10.522 11.737 10.6099 11.723 10.6556 11.6615L11.1583 10.9759C11.204 10.9109 11.19 10.823 11.1267 10.779Z"
      fill="#FBB13C"
    />
  </SvgIcon>
);

export default Timer;
