import camelCase from 'lodash/camelCase';
import { ProductParametersKey } from 'storage/agreements/agreements.model';

interface ProductParametersData {
  repaidInstallmentCount?: string;
  installmentsCount?: string;
  installmentsAmount?: string;
  nextInstallmentAmount?: string;
  nextInstallmentPayment?: string;
  firstInstallmentPayment?: string;
  lastInstallmentPayment?: string;
  remissionPercent?: string;
  resignationPercent?: string;
  remissionAmount?: string;
  resignationAmount?: string;
  lastInstallmentAmount?: string;
  frozenInterestsAmount?: string;
  payoffDay?: string;
  payoffAmount?: string;
  prerequisitePaymentAmount?: string;
  prerequisitePaymentDate?: string;
}

const getProductParametersValues = (
  productParameters: [
    {
      key: ProductParametersKey;
      value: string;
    }
  ]
) => {
  const parametersObj: { [key: string]: string } = {};
  productParameters.forEach(({ key, value }) => {
    parametersObj[camelCase(key)] = value;
  });

  return parametersObj as ProductParametersData;
};

export default getProductParametersValues;
