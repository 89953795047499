import { ReactNode, useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'storage/store';
import { Url } from 'utils/urls';
import getNotificationText from 'pages/Dashboard/pages/Main/components/Notifications/getNotificationText';
import { DesktopMenuAnchorContext } from '../../utils/desktopMenuAnchorContext';
import { MenuVisibilityContext } from '../../utils/menuVisibilityContext';
import sortNotifications from '../../utils/sortNotifications';
import MenuItem from '../../styled/MenuItem';
import { LinkStyled } from '../../styled/LinkStyled';
import NotificationItemWrapper from '../../styled/NotificationItemWrapper';
import NotificationItem from './NotificationItem';

const OverduePaymentsNotifications = () => {
  const { t } = useTranslation();
  const notifications = useSelector((state: RootState) => state.notifications);
  const [notificationsComponents, setNotificationsComponents] = useState<
    ReactNode[]
  >([]);
  const { setNotificationAnchor } = useContext(DesktopMenuAnchorContext);
  const { setNotificationsVisible } = useContext(MenuVisibilityContext);
  const notificationArray = sortNotifications(notifications);

  useEffect(() => {
    setNotificationsComponents(paymentsNotifications());
  }, [notifications]);

  const paymentsNotifications = useCallback(
    () =>
      notificationArray.map(
        ([
          agreementId,
          {
            amountAndDateData,
            type,
            variant,
            daysCount,
            isMultipleInstallmentsOverdue,
            paymentState
          }
        ]) => (
          <MenuItem
            onClick={() => {
              setNotificationsVisible!(false);
              setNotificationAnchor!(null);
            }}
            key={agreementId}
          >
            <LinkStyled
              to={`${Url.case}/${agreementId}${Url.payment}`}
              state={paymentState}
              data-link
              $isNotificationLink
            >
              <NotificationItemWrapper>
                <NotificationItem
                  icon="exclamationMarkCircle"
                  color={variant}
                  text={getNotificationText(
                    daysCount,
                    amountAndDateData,
                    type,
                    t,
                    isMultipleInstallmentsOverdue!
                  )}
                  linkText={t('NOTIFICATIONS_LINK_PAY')}
                  fontWeight={700}
                />
              </NotificationItemWrapper>
            </LinkStyled>
          </MenuItem>
        )
      ),
    [notificationArray]
  );

  return <>{notificationsComponents}</>;
};

export default OverduePaymentsNotifications;
