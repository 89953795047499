import {
  IAmountAndDateData,
  NotificationVariant
} from 'components/SettlementNotificationsBox/SettlementNotifications.model';
import { IPaymentsOption } from 'pages/Dashboard/pages/Payment/Payment.model';

export enum NotificationTextType {
  INSTALLMENT = 'INSTALLMENT',
  DEFAULT = 'DEFAULT'
}

export interface Notification {
  variant: NotificationVariant;
  type: NotificationTextType;
  amountAndDateData: IAmountAndDateData;
  paymentState: {
    paymentOptions: IPaymentsOption[];
    isOverpaymentMessage: boolean;
    totalDebtAmountInSystemCurrency: number;
  };
  isMultipleInstallmentsOverdue?: boolean;
  daysCount: number;
}

export interface NotificationsState {
  [key: number]: Notification;
}
