import { parse } from 'date-fns';
import { Notification } from 'storage/notifications/notifications.model';

const sortNotifications = (notifications: { [key: number]: Notification }) =>
  Object.entries(notifications).sort(([, notificationA], [, notificationB]) => {
    const parsedDateA = parse(
      notificationA.amountAndDateData.date!,
      'd.MM.yyyy',
      new Date()
    );
    const parsedDateB = parse(
      notificationB.amountAndDateData.date!,
      'd.MM.yyyy',
      new Date()
    );

    // @ts-ignore
    return parsedDateA - parsedDateB;
  });

export default sortNotifications;
