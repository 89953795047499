import { TFunction } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import getDifferenceInDays from 'utils/getDifferenceInDays';
import { Constant } from 'utils/consts';
import { NotificationVariant } from 'components/SettlementNotificationsBox/SettlementNotifications.model';
import { TWO_WEEKS_PERIOD } from './getInstallmentsData/getInstallmentsNotifications';
import { WaitingPayments } from './getInstallmentsData/getInstallmentsData';

const getPrerequisitePayment = (
  t: TFunction,
  waitingPaymentsAmountByCurrency: WaitingPayments,
  prerequisitePaymentAmount: number,
  prerequisitePaymentDate?: string
) => {
  if (!prerequisitePaymentDate) {
    return [];
  }
  const days = getDifferenceInDays(prerequisitePaymentDate);
  const notifications = [];
  if (days < Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      waitingPaymentsAmountByCurrency.PLN < prerequisitePaymentAmount
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t('SETTLEMENT_NOTIFICATION_PREREQUISITE_OVERDUE')
      });
    }
  }
  if (days === Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      waitingPaymentsAmountByCurrency.PLN < prerequisitePaymentAmount
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t('SETTLEMENT_NOTIFICATION_PREREQUISITE_TODAY_PAYMENT')
      });
    }
  }
  if (days < TWO_WEEKS_PERIOD && days > Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      waitingPaymentsAmountByCurrency.PLN < prerequisitePaymentAmount
    ) {
      notifications.push({
        variant: NotificationVariant.WARNING,
        message: t('SETTLEMENT_NOTIFICATION_PREREQUISITE_FUTURE_interval', {
          postProcess: 'interval',
          count: days
        })
      });
    }
  }
  return notifications;
};

export default getPrerequisitePayment;
