import { TFunction } from 'react-i18next';
import { IAmountAndDateData } from 'components/SettlementNotificationsBox/SettlementNotifications.model';
import { NotificationTextType } from 'storage/notifications/notifications.model';
import { Constant } from 'utils/consts';
import { formatCurrency } from 'utils/formatCurrency';
import { TWO_WEEKS_PERIOD } from 'pages/Dashboard/utils/getInstallmentsData/getInstallmentsNotifications';

const getNotificationText = (
  daysCount: number,
  amountAndDateData: IAmountAndDateData,
  type: NotificationTextType,
  t: TFunction,
  isMultipleInstallmentsOverdue: boolean
) => {
  const { currency, amount } = amountAndDateData;

  const formattedCurrency = formatCurrency(
    'plain',
    amount,
    currency,
    true,
    true,
    true,
    true
  ) as string;
  const money = formattedCurrency.replace(/ /, '\u00a0');
  if (isMultipleInstallmentsOverdue) {
    return t('COMMON_NOTIFICATION_PAST_INSTALLMENTS', { money });
  }
  if (daysCount < Constant.zero) {
    return t(`COMMON_NOTIFICATION_PAST_${type}`, { money });
  }
  if (daysCount === Constant.zero) {
    return t(`COMMON_NOTIFICATION_TODAY_${type}`, { money });
  }
  if (daysCount < TWO_WEEKS_PERIOD && daysCount > Constant.zero) {
    return t(`COMMON_NOTIFICATION_FUTURE_${type}_interval`, {
      postProcess: 'interval',
      count: daysCount,
      money
    });
  }
  return '';
};

export default getNotificationText;
