import { SvgIcon } from '@mui/material';

const FileYellow = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 32 35" fill="none">
    <path
      d="M14.0385 3.9309H4.34696C4.25809 3.93074 4.17292 3.89533 4.11013 3.83243C4.04735 3.76953 4.01208 3.68429 4.01208 3.59542C4.01208 3.50655 4.04735 3.42131 4.11013 3.35842C4.17292 3.29552 4.25809 3.26011 4.34696 3.25995H14.0385C14.1275 3.25995 14.2128 3.29529 14.2757 3.35821C14.3386 3.42112 14.374 3.50645 14.374 3.59542C14.374 3.6844 14.3386 3.76973 14.2757 3.83264C14.2128 3.89555 14.1275 3.9309 14.0385 3.9309Z"
      fill="white"
    />
    <path
      d="M11.6198 6.24194H4.34696C4.25809 6.24178 4.17292 6.20636 4.11013 6.14347C4.04735 6.08057 4.01208 5.99533 4.01208 5.90646C4.01208 5.81759 4.04735 5.73235 4.11013 5.66945C4.17292 5.60656 4.25809 5.57114 4.34696 5.57098H11.6198C11.7087 5.57114 11.7939 5.60656 11.8566 5.66945C11.9194 5.73235 11.9547 5.81759 11.9547 5.90646C11.9547 5.99533 11.9194 6.08057 11.8566 6.14347C11.7939 6.20636 11.7087 6.24178 11.6198 6.24194Z"
      fill="white"
    />
    <path
      d="M28.6273 34.8329H0.372753C0.273928 34.8328 0.179184 34.7935 0.109304 34.7236C0.0394243 34.6537 0.0001131 34.559 0 34.4601V3.37275C0.0001131 3.27393 0.0394243 3.17918 0.109304 3.1093C0.179184 3.03942 0.273928 3.00011 0.372753 3H28.6273C28.7261 3.00011 28.8208 3.03942 28.8907 3.1093C28.9606 3.17918 28.9999 3.27393 29 3.37275V34.4601C28.9999 34.559 28.9606 34.6537 28.8907 34.7236C28.8208 34.7935 28.7261 34.8328 28.6273 34.8329Z"
      fill="white"
    />
    <path
      d="M28.6273 34.8329H0.372753C0.273928 34.8328 0.179184 34.7935 0.109304 34.7236C0.0394243 34.6537 0.0001131 34.559 0 34.4601V3.37275C0.0001131 3.27393 0.0394243 3.17918 0.109304 3.1093C0.179184 3.03942 0.273928 3.00011 0.372753 3H28.6273C28.7261 3.00011 28.8208 3.03942 28.8907 3.1093C28.9606 3.17918 28.9999 3.27393 29 3.37275V34.4601C28.9999 34.559 28.9606 34.6537 28.8907 34.7236C28.8208 34.7935 28.7261 34.8328 28.6273 34.8329ZM0.372753 3.1491C0.313457 3.14916 0.256609 3.17275 0.21468 3.21467C0.172751 3.2566 0.149168 3.31345 0.149103 3.37275V34.4601C0.149168 34.5194 0.172751 34.5763 0.21468 34.6182C0.256609 34.6601 0.313458 34.6837 0.372753 34.6838H28.6273C28.6865 34.6837 28.7434 34.6601 28.7853 34.6182C28.8272 34.5763 28.8508 34.5194 28.8509 34.4601V3.37275C28.8508 3.31345 28.8272 3.2566 28.7853 3.21467C28.7434 3.17275 28.6865 3.14916 28.6273 3.1491H0.372753Z"
      fill="#3F3D56"
    />
    <path
      d="M13.73 15.3007H3.54116C3.45222 15.3006 3.36695 15.2653 3.30406 15.2024C3.24117 15.1395 3.20579 15.0542 3.20569 14.9653V7.95755C3.20579 7.8686 3.24117 7.78333 3.30406 7.72044C3.36695 7.65755 3.45222 7.62217 3.54116 7.62207H13.73C13.8189 7.62217 13.9042 7.65755 13.9671 7.72044C14.0299 7.78333 14.0653 7.8686 14.0654 7.95755V14.9653C14.0653 15.0542 14.0299 15.1395 13.9671 15.2024C13.9042 15.2653 13.8189 15.3006 13.73 15.3007Z"
      fill="#808080"
    />
    <path
      d="M25.4589 19.1401H3.54116C3.45219 19.1401 3.36686 19.1048 3.30395 19.0419C3.24103 18.979 3.20569 18.8936 3.20569 18.8047C3.20569 18.7157 3.24103 18.6303 3.30395 18.5674C3.36686 18.5045 3.45219 18.4692 3.54116 18.4692H25.4589C25.5479 18.4692 25.6332 18.5045 25.6961 18.5674C25.759 18.6303 25.7944 18.7157 25.7944 18.8047C25.7944 18.8936 25.759 18.979 25.6961 19.0419C25.6332 19.1048 25.5479 19.1401 25.4589 19.1401Z"
      fill="#CCCCCC"
    />
    <path
      d="M20.1657 21.4511H3.54104C3.45207 21.4511 3.36674 21.4158 3.30382 21.3528C3.24091 21.2899 3.20557 21.2046 3.20557 21.1156C3.20557 21.0267 3.24091 20.9413 3.30382 20.8784C3.36674 20.8155 3.45207 20.7802 3.54104 20.7802H20.1657C20.2547 20.7802 20.34 20.8155 20.4029 20.8784C20.4658 20.9413 20.5012 21.0267 20.5012 21.1156C20.5012 21.2046 20.4658 21.2899 20.4029 21.3528C20.34 21.4158 20.2547 21.4511 20.1657 21.4511Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.3843 10.6414H15.6928C15.6038 10.6414 15.5185 10.6061 15.4556 10.5431C15.3926 10.4802 15.3573 10.3949 15.3573 10.3059C15.3573 10.217 15.3926 10.1316 15.4556 10.0687C15.5185 10.0058 15.6038 9.97046 15.6928 9.97046H25.3843C25.4733 9.97046 25.5586 10.0058 25.6215 10.0687C25.6844 10.1316 25.7198 10.217 25.7198 10.3059C25.7198 10.3949 25.6844 10.4802 25.6215 10.5431C25.5586 10.6061 25.4733 10.6414 25.3843 10.6414Z"
      fill="#808080"
    />
    <path
      d="M22.9658 12.9524H15.6929C15.604 12.9523 15.5189 12.9169 15.4561 12.854C15.3933 12.7911 15.358 12.7058 15.358 12.617C15.358 12.5281 15.3933 12.4429 15.4561 12.38C15.5189 12.3171 15.604 12.2817 15.6929 12.2815H22.9658C23.0546 12.2817 23.1398 12.3171 23.2026 12.38C23.2654 12.4429 23.3006 12.5281 23.3006 12.617C23.3006 12.7058 23.2654 12.7911 23.2026 12.854C23.1398 12.9169 23.0546 12.9523 22.9658 12.9524Z"
      fill="#808080"
    />
    <path
      d="M25.4589 23.7622H3.54116C3.45219 23.7622 3.36686 23.7269 3.30395 23.6639C3.24103 23.601 3.20569 23.5157 3.20569 23.4267C3.20569 23.3377 3.24103 23.2524 3.30395 23.1895C3.36686 23.1266 3.45219 23.0912 3.54116 23.0912H25.4589C25.5479 23.0912 25.6332 23.1266 25.6961 23.1895C25.759 23.2524 25.7944 23.3377 25.7944 23.4267C25.7944 23.5157 25.759 23.601 25.6961 23.6639C25.6332 23.7269 25.5479 23.7622 25.4589 23.7622Z"
      fill="#CCCCCC"
    />
    <path
      d="M20.1656 26.0732H3.54093C3.45206 26.0731 3.36689 26.0377 3.3041 25.9748C3.24132 25.9119 3.20605 25.8266 3.20605 25.7378C3.20605 25.6489 3.24132 25.5636 3.3041 25.5008C3.36689 25.4379 3.45206 25.4024 3.54093 25.4023H20.1656C20.2546 25.4023 20.3399 25.4376 20.4028 25.5005C20.4657 25.5635 20.5011 25.6488 20.5011 25.7378C20.5011 25.8267 20.4657 25.9121 20.4028 25.975C20.3399 26.0379 20.2546 26.0732 20.1656 26.0732Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4587 28.3843H3.54093C3.45206 28.3842 3.36689 28.3488 3.3041 28.2859C3.24132 28.223 3.20605 28.1377 3.20605 28.0489C3.20605 27.96 3.24132 27.8747 3.3041 27.8118C3.36689 27.7489 3.45206 27.7135 3.54093 27.7134H25.4587C25.5476 27.7134 25.633 27.7487 25.6959 27.8116C25.7588 27.8745 25.7941 27.9599 25.7941 28.0489C25.7941 28.1378 25.7588 28.2232 25.6959 28.2861C25.633 28.349 25.5476 28.3843 25.4587 28.3843Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4589 30.6954H3.54116C3.45219 30.6954 3.36686 30.66 3.30395 30.5971C3.24103 30.5342 3.20569 30.4489 3.20569 30.3599C3.20569 30.2709 3.24103 30.1856 3.30395 30.1227C3.36686 30.0598 3.45219 30.0244 3.54116 30.0244H25.4589C25.5479 30.0244 25.6332 30.0598 25.6961 30.1227C25.759 30.1856 25.7944 30.2709 25.7944 30.3599C25.7944 30.4489 25.759 30.5342 25.6961 30.5971C25.6332 30.66 25.5479 30.6954 25.4589 30.6954Z"
      fill="#CCCCCC"
    />
    <path
      d="M28.3648 7.34376C26.6462 7.96666 24.7487 7.08013 24.1265 5.36366C23.5044 3.64718 24.3933 1.75075 26.1119 1.12785C27.8304 0.504956 29.728 1.39148 30.3501 3.10795C30.9722 4.82443 30.0834 6.72087 28.3648 7.34376Z"
      fill="#FBB13C"
    />
  </SvgIcon>
);

export default FileYellow;
