import {
  AvailableDocuments,
  DocumentsState,
  SharedDocument,
  SharedDocumentForUser
} from './documents.model';

const reducers = {
  storeAvailableDocuments: (
    state: DocumentsState,
    availableDocuments: AvailableDocuments[]
  ) => ({
    ...state,
    availableDocuments
  }),
  storeSharedDocumentsForAgreement: (
    state: DocumentsState,
    sharedDocumentsForAgreement: SharedDocument[]
  ) => ({
    ...state,
    sharedDocumentsForAgreement
  }),
  storeSharedDocumentsForUser: (
    state: DocumentsState,
    sharedDocumentsForUser: SharedDocumentForUser[]
  ) => ({
    ...state,
    sharedDocumentsForUser
  })
};

export default reducers;
