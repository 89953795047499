import {
  IHistoryData,
  IPlanData,
  PlanStatusType
} from 'storage/agreements/agreements.model';

export interface ISchedule {
  isModalOpen: boolean;
  handleCloseButton: () => void;
  planData?: IPlanData[];
  historyData: IHistoryData[];
  handlePayButton?: (status: PlanStatusType, installmentAmount: number) => void;
  currentTabIndex?: number;
}

export interface IStatusComponent {
  onClick?: (status: PlanStatusType, installmentAmount: number) => void;
  status: PlanStatusType;
  amount: number;
}

export interface IPlan {
  planData: IPlanData[];
  handlePayButton?: (status: PlanStatusType, installmentAmount: number) => void;
  windowHeight?: number;
}

export enum PlanStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  CURRENT = 'CURRENT',
  EXPIRED = 'EXPIRED'
}
