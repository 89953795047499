import { RootDispatch } from 'storage/store';
import { TFunction } from 'react-i18next';
import {
  Agreement,
  SimulationStatusEnum
} from 'storage/agreements/agreements.model';
import { EMPTY_ARRAY_LENGTH, Index } from './consts';
import getProductParametersValues from '../pages/Dashboard/utils/getProductParametersValues';
import checkDebtIsPaidOff from './checkDebtIsPaidOff';
import { IPaymentsOption } from '../pages/Dashboard/pages/Payment/Payment.model';
import getSettledNotificationsData from '../pages/Dashboard/utils/getSettledNotificationsData';
import sortNotifications from '../pages/Dashboard/utils/sortNotifications';
import getDefaultAgreementPaymentsOptions from '../pages/Dashboard/utils/getDefaultAgreementPaymentsOptions';
import { NotificationTextType } from '../storage/notifications/notifications.model';
import getDifferenceInDays from './getDifferenceInDays';

const setPaymentNotifications = (
  settledCases: Agreement[],
  t: TFunction<'translation'>,
  dispatch: RootDispatch
) => {
  settledCases.forEach((agreement) => {
    const { generalData } = agreement;
    const {
      agreementId,
      productData,
      currency,
      overduePaymentsAmount,
      totalDebtAmountWithoutRemissionByCurrency,
      totalDebtAmountByCurrency,
      totalDebtAmountInSystemCurrency,
      waitingPaymentsAmountByCurrency
    } = generalData;
    const [, debtAmountWithoutRemission] = Object.entries(
      totalDebtAmountWithoutRemissionByCurrency
    )[Index.zero];
    const [, totalDebtAmount] = Object.entries(totalDebtAmountByCurrency)[
      Index.zero
    ];
    const {
      overdue,
      productParameters,
      simulationStatus,
      prerequisitePaymentLeft,
      installments: installmentsPlan
    } = productData;
    const {
      nextInstallmentAmount,
      payoffDay,
      prerequisitePaymentDate,
      prerequisitePaymentAmount,
      installmentsCount,
      firstInstallmentPayment,
      installmentsAmount
    } = getProductParametersValues(productParameters);
    const { isProductDebtPaidOff } = checkDebtIsPaidOff(agreement);
    const isPrerequisitePayment =
      simulationStatus ===
        SimulationStatusEnum.WAITING_TO_SATISFY_PREREQUISITES ||
      !!(
        prerequisitePaymentDate &&
        prerequisitePaymentAmount &&
        simulationStatus === SimulationStatusEnum.CONFIRMED
      );
    const isInstallmentPlan = !isPrerequisitePayment && !!installmentsCount;

    const paymentOptions: IPaymentsOption[] = [];
    const { notifications, amountAndDateData, isMultipleInstallmentsOverdue } =
      getSettledNotificationsData(
        isPrerequisitePayment,
        prerequisitePaymentLeft,
        paymentOptions,
        currency,
        t,
        isInstallmentPlan,
        installmentsPlan,
        overdue,
        overduePaymentsAmount,
        debtAmountWithoutRemission,
        totalDebtAmount,
        waitingPaymentsAmountByCurrency,
        prerequisitePaymentAmount,
        nextInstallmentAmount,
        firstInstallmentPayment,
        prerequisitePaymentDate,
        payoffDay,
        installmentsAmount
      );
    sortNotifications(notifications);
    paymentOptions.push(...getDefaultAgreementPaymentsOptions(agreement));

    if (notifications.length !== EMPTY_ARRAY_LENGTH && !isProductDebtPaidOff) {
      dispatch.notifications.storeNotification(
        {
          variant: notifications[Index.zero].variant,
          type: isInstallmentPlan
            ? NotificationTextType.INSTALLMENT
            : NotificationTextType.DEFAULT,
          isMultipleInstallmentsOverdue,
          amountAndDateData,
          paymentState: {
            paymentOptions,
            isOverpaymentMessage: isInstallmentPlan,
            totalDebtAmountInSystemCurrency
          },
          daysCount: getDifferenceInDays(amountAndDateData.date)
        },
        agreementId
      );
    }
    if (notifications.length === EMPTY_ARRAY_LENGTH) {
      dispatch.notifications.removeNotification(agreementId);
    }
  });
};

export default setPaymentNotifications;
