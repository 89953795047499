import {
  IPaymentsOption,
  PaymentType
} from 'pages/Dashboard/pages/Payment/Payment.model';

const updateInstallmentPaymentOption = (
  paymentOptions: IPaymentsOption[],
  isSingleInstalmentOverdue: boolean,
  isMultipleInstallmentsOverdue: boolean,
  value: number
) => {
  const installmentPaymentOption = isSingleInstalmentOverdue
    ? {
        type: PaymentType.OVERDUE_INSTALLMENT,
        value
      }
    : isMultipleInstallmentsOverdue
    ? {
        type: PaymentType.OVERDUE_INSTALLMENTS,
        value
      }
    : {
        type: PaymentType.NEXT_INSTALLMENT,
        value
      };
  paymentOptions.push(installmentPaymentOption);
};

export default updateInstallmentPaymentOption;
