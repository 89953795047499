import { SvgIcon } from '@mui/material';

const Download = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 22 20" fill="none">
    <path
      d="M10.831 13.9862C10.8511 14.0118 10.8767 14.0325 10.9059 14.0467C10.9352 14.061 10.9673 14.0684 10.9998 14.0684C11.0323 14.0684 11.0644 14.061 11.0936 14.0467C11.1229 14.0325 11.1485 14.0118 11.1685 13.9862L14.1685 10.1906C14.2784 10.0514 14.1793 9.8451 13.9998 9.8451H12.015V0.780814C12.015 0.662957 11.9185 0.566528 11.8007 0.566528H10.1935C10.0757 0.566528 9.97925 0.662957 9.97925 0.780814V9.84242H7.99979C7.82033 9.84242 7.72122 10.0487 7.83104 10.188L10.831 13.9862ZM20.8034 13.0487H19.1962C19.0784 13.0487 18.9819 13.1451 18.9819 13.263V17.388H3.01765V13.263C3.01765 13.1451 2.92122 13.0487 2.80336 13.0487H1.19622C1.07836 13.0487 0.981934 13.1451 0.981934 13.263V18.5665C0.981934 19.0406 1.36497 19.4237 1.83908 19.4237H20.1605C20.6346 19.4237 21.0176 19.0406 21.0176 18.5665V13.263C21.0176 13.1451 20.9212 13.0487 20.8034 13.0487Z"
      fill="#006D48"
    />
  </SvgIcon>
);

export default Download;
