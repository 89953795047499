import { TFunction } from 'react-i18next';
import { IPlanData } from 'storage/agreements/agreements.model';
import { EMPTY_ARRAY_LENGTH } from 'utils/consts';
import getDifferenceInDays from 'utils/getDifferenceInDays';
import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import { IPaymentsOption, PaymentType } from '../pages/Payment/Payment.model';
import getPrerequisitePayment from './getPrerequisitePayment';
import getInstallmentsData from './getInstallmentsData/getInstallmentsData';
import updateInstallmentPaymentOption from './getInstallmentsData/updateInstallmentPaymentOption';
import getInstallmentsNotifications from './getInstallmentsData/getInstallmentsNotifications';
import getPayOffNotifications from './getPayOffNotifications';

const getSettledNotificationsData = (
  isPrerequisitePayment: boolean,
  prerequisitePaymentLeft: number,
  paymentOptions: IPaymentsOption[],
  currency: CurrencyType,
  t: TFunction,
  isInstallmentPlan: boolean,
  installmentsPlan: IPlanData[],
  overdue: boolean,
  overduePaymentsAmount: number,
  debtAmountWithoutRemission: number,
  totalDebtAmount: number,
  waitingPaymentsAmountByCurrency: {
    [key in CurrencyType]: number;
  },
  prerequisitePaymentAmount?: string,
  nextInstallmentAmount?: string,
  firstInstallmentPayment?: string,
  prerequisitePaymentDate?: string,
  payoffDay?: string,
  installmentsAmount?: string
) => {
  if (isPrerequisitePayment) {
    paymentOptions.push({
      type: PaymentType.CONDITIONAL_PAYMENT,
      value: prerequisitePaymentLeft
    });

    return {
      amountAndDateData: {
        amount: prerequisitePaymentLeft,
        date: prerequisitePaymentDate,
        currency
      },
      notifications: getPrerequisitePayment(
        t,
        waitingPaymentsAmountByCurrency,
        Number(prerequisitePaymentAmount),
        prerequisitePaymentDate
      )
    };
  }
  if (isInstallmentPlan && installmentsPlan.length > EMPTY_ARRAY_LENGTH) {
    const {
      notifications,
      isSingleInstalmentOverdue,
      isMultipleInstallmentsOverdue,
      dateToDisplay
    } = getInstallmentsData(
      installmentsPlan as IPlanData[],
      t,
      waitingPaymentsAmountByCurrency,
      overduePaymentsAmount,
      Number(nextInstallmentAmount)
    );

    const amount = overdue
      ? overduePaymentsAmount
      : Number(nextInstallmentAmount || '0');

    updateInstallmentPaymentOption(
      paymentOptions,
      isSingleInstalmentOverdue,
      isMultipleInstallmentsOverdue,
      amount
    );

    return {
      notifications,
      isSingleInstalmentOverdue,
      isMultipleInstallmentsOverdue,
      amountAndDateData: {
        date: dateToDisplay,
        currency,
        amount
      }
    };
  }
  if (isInstallmentPlan && installmentsPlan.length === EMPTY_ARRAY_LENGTH) {
    const value = overdue
      ? overduePaymentsAmount
      : installmentsAmount
      ? Number(installmentsAmount)
      : Number(nextInstallmentAmount);
    const { notifications, isSingleInstalmentOverdue } =
      getInstallmentsNotifications(
        t,
        [getDifferenceInDays(firstInstallmentPayment)],
        waitingPaymentsAmountByCurrency,
        overduePaymentsAmount,
        Number(nextInstallmentAmount)
      );
    paymentOptions.push({
      type: isSingleInstalmentOverdue
        ? PaymentType.OVERDUE_INSTALLMENT
        : PaymentType.NEXT_INSTALLMENT,
      value
    });
    return {
      notifications,
      isSingleInstalmentOverdue,
      amountAndDateData: {
        date: firstInstallmentPayment,
        currency,
        amount: value
      }
    };
  }

  return {
    notifications: getPayOffNotifications(
      t,
      totalDebtAmount,
      waitingPaymentsAmountByCurrency,
      payoffDay
    ),
    amountAndDateData: {
      amount: debtAmountWithoutRemission,
      date: payoffDay,
      currency
    }
  };
};

export default getSettledNotificationsData;
