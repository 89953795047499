import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import theme from 'App.theme';
import { Logo } from 'components/Logo';
import { Url } from 'utils/urls';
import { menuClickEvent } from 'utils/events';
import sendEvent from 'utils/sendEvent';
import { IsLoggedIn } from 'utils/user';
import LogoLink from '../styled/LogoLink';
import { MenuVisibilityContext } from '../utils/menuVisibilityContext';

interface IAppLogo {
  size?: number;
}

const AppLogo = ({ size }: IAppLogo) => {
  const desktopMenuLogoSize = 46;
  const mobileMenuLogoSize = 35;
  const logoLinkHref = IsLoggedIn() ? Url.dashboard : Url.main;
  const desktopBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const { setNotificationsVisible } = useContext(MenuVisibilityContext);
  const logoSize =
    size || desktopBreakpoint ? desktopMenuLogoSize : mobileMenuLogoSize;

  if (!setNotificationsVisible) return null;
  return (
    <LogoLink
      to={logoLinkHref}
      onClick={() => {
        setNotificationsVisible(false);
        sendEvent({
          labelText: 'logo',
          ...menuClickEvent
        });
      }}
      data-link
    >
      <Logo size={logoSize} />
    </LogoLink>
  );
};

export default AppLogo;
