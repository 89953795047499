type NotificationType = 'paymentsDone' | 'sharedDocuments';

const checkNotificationFreshness = (
  onDashboard: boolean,
  agreementId: number,
  notificationType: NotificationType
) => {
  const clickedNotifications = localStorage.getItem(notificationType);
  if (clickedNotifications) {
    const isCurrentNotificationClicked =
      JSON.parse(clickedNotifications).includes(agreementId);
    return onDashboard && !isCurrentNotificationClicked;
  }
  return onDashboard;
};

export default checkNotificationFreshness;
