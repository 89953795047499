import isEmpty from 'lodash/isEmpty';
import { Agreement } from 'storage/agreements/agreements.model';
import { Constant, Index } from 'utils/consts';
import {
  IPaymentsOption,
  PaymentType
} from 'pages/Dashboard/pages/Payment/Payment.model';
import getProductParametersValues from 'pages/Dashboard/utils/getProductParametersValues';

const getDefaultAgreementPaymentsOptions = (agreement?: Agreement) => {
  const defaultPaymentsOptions: IPaymentsOption[] = [];

  if (!agreement || isEmpty(agreement)) {
    return defaultPaymentsOptions;
  }

  if (!agreement.generalData.productData) {
    defaultPaymentsOptions.push(
      {
        type: PaymentType.WHOLE_AMOUNT,
        value: agreement.generalData.totalDebtAmountInSystemCurrency
      },
      {
        type: PaymentType.CUSTOM_AMOUNT,
        value: Constant.zero
      }
    );
    return defaultPaymentsOptions;
  }
  const { productStatus, productParameters } =
    agreement.generalData.productData;
  const { remissionAmount, resignationAmount, installmentsCount } =
    getProductParametersValues(productParameters);

  if (
    productStatus ||
    !(installmentsCount && (resignationAmount || remissionAmount))
  ) {
    const [, totalDebtAmountWithoutRemission] = Object.entries(
      agreement.generalData.totalDebtAmountWithoutRemissionByCurrency
    )[Index.zero];
    defaultPaymentsOptions.push(
      {
        type: PaymentType.WHOLE_AMOUNT,
        value: totalDebtAmountWithoutRemission
      },
      {
        type: PaymentType.CUSTOM_AMOUNT,
        value: Constant.zero
      }
    );
    return defaultPaymentsOptions;
  }
  defaultPaymentsOptions.push({
    type: PaymentType.CUSTOM_AMOUNT,
    value: Constant.zero
  });

  return defaultPaymentsOptions;
};

export default getDefaultAgreementPaymentsOptions;
