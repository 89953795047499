import { format } from 'date-fns';
import { Grid, Typography } from '@mui/material';
import theme from 'App.theme';
import { Constant } from 'utils/consts';
import { IconType } from 'components/Icon/Icon.model';
import { Icon } from 'components/Icon';
import { useCallback } from 'react';

interface INotificationItem {
  icon: IconType;
  color: 'error' | 'warning' | 'success';
  text: string;
  linkText: string;
  fontWeight?: number;
}

const NotificationItem = ({
  icon,
  color,
  text,
  linkText,
  fontWeight = Constant.sixHundred
}: INotificationItem) => {
  const getColor = useCallback(() => {
    switch (color) {
      case 'success': {
        return theme.palette.success.main;
      }
      case 'warning': {
        return theme.palette.warning.main;
      }
      default: {
        return theme.palette.error.main;
      }
    }
  }, [color]);

  return (
    <Grid container>
      <Grid item xs={12} pb={1}>
        <Typography variant="subtitle1" color="common.darkGrey">
          {format(new Date(), 'dd.MM.yyyy')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={1} pr="12px">
          <Icon
            icon={icon}
            fontSize="small"
            color={color}
            sx={{ verticalAlign: 'baseline' }}
          />
        </Grid>
        <Grid item xs={9} lg={8} pr={{ xs: 1, md: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight, whiteSpace: 'break-spaces' }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          lg={3}
          container
          justifyContent="flex-end"
          wrap="nowrap"
        >
          <Grid item>
            <Typography
              variant="subtitle1"
              color={`${color}.main`}
              sx={{ display: 'inline' }}
            >
              {linkText}
            </Typography>
          </Grid>
          <Grid item>
            <Icon
              icon="chevronRight"
              fontSize="small"
              sx={{
                marginLeft: '10px',
                color: getColor(),
                verticalAlign: 'middle'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationItem;
