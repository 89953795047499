import { Constant } from 'utils/consts';
import {
  INotification,
  NotificationVariant
} from 'components/SettlementNotificationsBox/SettlementNotifications.model';

const sortNotifications = (notifications: INotification[]) => {
  notifications.sort((a, b) => {
    if (
      a.variant === NotificationVariant.ERROR &&
      b.variant === NotificationVariant.WARNING
    ) {
      return Constant.minusOne;
    }
    return Constant.one;
  });
};

export default sortNotifications;
