import { SvgIcon } from '@mui/material';

const Calendar2 = ({ ...props }) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M11.3161 1.24282H8.87858V0.314253C8.87858 0.250413 8.82634 0.198181 8.7625 0.198181H7.95C7.88617 0.198181 7.83393 0.250413 7.83393 0.314253V1.24282H4.11965V0.314253C4.11965 0.250413 4.06741 0.198181 4.00358 0.198181H3.19108C3.12724 0.198181 3.075 0.250413 3.075 0.314253V1.24282H0.637504C0.380695 1.24282 0.173218 1.4503 0.173218 1.70711V11.341C0.173218 11.5978 0.380695 11.8053 0.637504 11.8053H11.3161C11.5729 11.8053 11.7804 11.5978 11.7804 11.341V1.70711C11.7804 1.4503 11.5729 1.24282 11.3161 1.24282ZM10.7357 10.7607H1.21786V5.24729H10.7357V10.7607ZM1.21786 4.26068V2.28747H3.075V2.9839C3.075 3.04773 3.12724 3.09997 3.19108 3.09997H4.00358C4.06741 3.09997 4.11965 3.04773 4.11965 2.9839V2.28747H7.83393V2.9839C7.83393 3.04773 7.88617 3.09997 7.95 3.09997H8.7625C8.82634 3.09997 8.87858 3.04773 8.87858 2.9839V2.28747H10.7357V4.26068H1.21786Z"
      fill="#808080"
    />
  </SvgIcon>
);

export default Calendar2;
