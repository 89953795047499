import { PaymentArgs } from 'storage/payment/payment.model';
import { PaymentDetails } from 'storage/agreements/agreements.model';

export enum PaymentType {
  WHOLE_AMOUNT = 'wholeAmount',
  NEXT_INSTALLMENT = 'nextInstallment',
  OVERDUE_INSTALLMENT = 'overdueInstallment',
  OVERDUE_INSTALLMENTS = 'overdueInstallments',
  CONDITIONAL_PAYMENT = 'conditionalPayment',
  CUSTOM_AMOUNT = 'customAmount'
}
export interface IPaymentsOption {
  type: PaymentType;
  value: number;
}

export interface PaymentState {
  state: {
    paymentOptions: IPaymentsOption[];
    isOverpaymentMessage: boolean;
    accountNumber: string;
    recipientName: string;
    paymentTitle: string;
    totalDebtAmountInSystemCurrency: number;
  };
}

export interface PaymentModel {
  onPayClick: (data: PaymentArgs) => void;
  paymentOptions: IPaymentsOption[];
  isOverpaymentMessage: boolean;
  totalDebtAmountInSystemCurrency: number;
}

export interface IPaymentDetails {
  paymentDetails: PaymentDetails;
  agreementId: number;
}

export interface PaymentForm {
  type: PaymentType;
  amount: number;
  customAmount: number | string;
  wholeAmount?: number;
}
