import { Agreement } from 'storage/agreements/agreements.model';
import checkNotificationFreshness from 'utils/checkNotificationFreshness';

const getRecentlyPaidAgreements = (
  agreements: Agreement[],
  menuNotifications?: boolean
) =>
  agreements.filter(({ generalData }) => {
    const { lastSettledPayment } = generalData;

    if (lastSettledPayment) {
      if (menuNotifications) {
        return true;
      }
      return checkNotificationFreshness(
        lastSettledPayment.onDashboard,
        generalData.agreementId,
        'paymentsDone'
      );
    }
    return false;
  });

export default getRecentlyPaidAgreements;
