import { Dispatch, SetStateAction } from 'react';
import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import {
  AdditionalCostTypes,
  PhaseStatusTypes
} from 'storage/agreements/agreements.model';
import { ColorType } from '../FormatCurrency/FormatCurrency.model';
import { ProductVariantType } from '../ProductBox/ProductBox.model';

export enum NotificationVariant {
  ERROR = 'error',
  WARNING = 'warning'
}

export interface INotification {
  variant: NotificationVariant;
  message: string;
}

export interface INotifications {
  notifications: INotification[];
}

export interface IAmountAndDate extends IAmountAndDateData {
  label: string;
  dateColor: string;
  amountColor: ColorType;
}

export interface IAmountAndDateData {
  amount: number;
  currency: CurrencyType;
  date?: string;
  oldAmount?: number;
  additionalCostsModalFn?: Dispatch<SetStateAction<boolean>>;
}

export type AmountByCurrency = {
  [type in CurrencyType]?: number;
};

export interface ISettlement {
  amountAndDateData: IAmountAndDateData;
  notifications: INotification[];
  payButtonCallback: () => void;
  isInstallmentPlan: boolean;
  isPrerequisitePayment: boolean;
  isTotalRepayment: boolean;
  isSingleInstalmentOverdue?: boolean;
  isMultipleInstallmentsOverdue?: boolean;
  waitingPaymentsAmountByCurrency: AmountByCurrency;
  isRiskOfTerminationOfContract: boolean;
  isDebtPaidOff?: boolean;
  isPrerequisitePaymentDone?: boolean;
  caseOfRepaidProduct?: string;
}

export interface ISettlementDashboard extends ISettlement {
  productName: string;
  agreementId: number;
  annexAvailable: boolean;
  phaseSegmentation: IPhaseSegmentation;
  originalCreditorName?: string;
  details?: IDetails;
  productType: string;
  isCaseBox?: boolean;
  isRiskOfTerminationOfContract: boolean;
}

export interface IAgreementAcceptance {
  agreementId: number;
  productVariant: ProductVariantType;
}

export interface IDetails {
  installmentsCount?: string;
  repaidInstallmentCount?: string;
  totalDebtAmount: number;
  debtAmountWithoutRemission?: number;
  currency: CurrencyType;
  additionalCostsModalFn?: Dispatch<SetStateAction<boolean>>;
}

export interface IPhaseSegmentation {
  phaseStatus: PhaseStatusTypes;
  additionalCost: AdditionalCostTypes;
}
