import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopMenuAnchorContext } from 'components/Menu/utils/desktopMenuAnchorContext';

export enum ScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN'
}

const SCROLL_ZERO_VALUE = 0;

const useScroll = (menuVisible: boolean, notificationVisible: boolean) => {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection | null>(null);
  const { setAnchorEl, setNotificationAnchor } = useContext(
    DesktopMenuAnchorContext
  );
  const minPageY = 64;
  const [, setBodyPosition] = useState(document.body.style.position);
  const { pathname } = useLocation();

  if (!setAnchorEl || !setNotificationAnchor) return null;
  useEffect(() => {
    setBodyPosition(document.body.style.position);
  }, [document.body.style.position]);

  useEffect(() => {
    setBodyPosition((prevState) => {
      if (prevState === 'fixed') {
        setScrollDirection(ScrollDirection.UP);
      }
      return document.body.style.position;
    });
  }, [pathname]);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    let wasModalClosed = false;
    let isModalOpen = false;

    const updateScrollDirection = () => {
      const { scrollY } = window;
      const minY = scrollY <= minPageY ? minPageY : SCROLL_ZERO_VALUE;

      if (document.body.style.position === '' && isModalOpen) {
        wasModalClosed = true;
      }
      isModalOpen = document.body.style.position === 'fixed';

      if (Math.abs(scrollY - lastScrollY) <= minY) {
        ticking = false;
        return;
      }

      setScrollDirection((prevState) => {
        if (isModalOpen || wasModalClosed) {
          wasModalClosed = false;
          return prevState;
        }
        return scrollY <= minY
          ? null
          : menuVisible || notificationVisible
          ? ScrollDirection.UP
          : scrollY > lastScrollY
          ? ScrollDirection.DOWN
          : ScrollDirection.UP;
      });

      lastScrollY = scrollY ?? SCROLL_ZERO_VALUE;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        setAnchorEl(null);
        setNotificationAnchor(null);
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDirection]);

  return scrollDirection;
};

export default useScroll;
