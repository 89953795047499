import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import getColor, { Color } from 'utils/getColor';

export const LinkStyled = styled(Link)<{
  $color?: Color;
  $isNotificationLink?: boolean;
}>`
  text-decoration: none;
  ${({ $color }) => $color && `color: ${getColor($color)};`}
  ${({ $isNotificationLink }) => $isNotificationLink && `&&& { padding: 0; }`}
`;
