import { TFunction } from 'react-i18next';
import { IPlanData } from 'storage/agreements/agreements.model';
import { CurrencyType } from 'utils/formatCurrency/formatCurrency.model';
import getInstallmentsToNotify from './getInstallmentsToNotify';
import getInstallmentsDays from './getInstallmentsDays';
import getInstallmentsNotifications from './getInstallmentsNotifications';

export type WaitingPayments = {
  [key in CurrencyType]: number;
};
const getInstallmentsData = (
  installmentsPlan: IPlanData[],
  t: TFunction,
  waitingPaymentsAmountByCurrency: WaitingPayments,
  overduePaymentsAmount: number,
  nextInstallmentAmount: number
) => {
  const { installments, dateToDisplay } = getInstallmentsToNotify(
    installmentsPlan as IPlanData[],
    overduePaymentsAmount,
    waitingPaymentsAmountByCurrency
  );
  const days = installments && getInstallmentsDays(installments);
  const data =
    days &&
    getInstallmentsNotifications(
      t,
      days,
      waitingPaymentsAmountByCurrency,
      overduePaymentsAmount,
      nextInstallmentAmount
    );
  return { dateToDisplay, ...data };
};

export default getInstallmentsData;
