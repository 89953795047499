import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState
} from 'react';

interface IMenuVisibilityContext {
  menuVisible: boolean;
  setVisible: null | Dispatch<SetStateAction<boolean>>;
  notificationsVisible: boolean;
  setNotificationsVisible: null | Dispatch<SetStateAction<boolean>>;
}

export const MenuVisibilityContext = createContext<IMenuVisibilityContext>({
  menuVisible: false,
  setVisible: null,
  notificationsVisible: false,
  setNotificationsVisible: null
});

export const MenuVisibilityProvider: React.FC<React.ReactNode> = ({
  children
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const val = React.useMemo(
    () => ({
      menuVisible,
      setVisible: setMenuVisible,
      notificationsVisible,
      setNotificationsVisible
    }),
    [menuVisible, notificationsVisible]
  );
  return (
    <MenuVisibilityContext.Provider value={val}>
      {children}
    </MenuVisibilityContext.Provider>
  );
};
