import { TFunction } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { ARRAY_WITH_ONE_ELEMENT_LENGTH, Constant } from 'utils/consts';
import { NotificationVariant } from 'components/SettlementNotificationsBox/SettlementNotifications.model';
import { WaitingPayments } from './getInstallmentsData';

export const TWO_WEEKS_PERIOD = 15;

const getInstallmentsNotifications = (
  t: TFunction,
  days: number[],
  waitingPaymentsAmountByCurrency: WaitingPayments,
  overduePaymentsAmount: number,
  nextInstallmentAmount: number
) => {
  let isMultipleInstallmentsOverdue = false;
  let isSingleInstalmentOverdue = false;
  const notifications = [];
  const overduePayments = days.filter((day) => day < Constant.zero);

  if (overduePayments.length > ARRAY_WITH_ONE_ELEMENT_LENGTH) {
    if (
      overduePaymentsAmount === null ||
      isEmpty(waitingPaymentsAmountByCurrency) ||
      waitingPaymentsAmountByCurrency.PLN < overduePaymentsAmount
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t(
          'SETTLEMENT_NOTIFICATION_MESSAGE_MULTIPLE_INSTALLMENTS_OVERDUE'
        )
      });
    }
    isMultipleInstallmentsOverdue = true;
  }
  if (overduePayments.length === ARRAY_WITH_ONE_ELEMENT_LENGTH) {
    if (
      overduePaymentsAmount === null ||
      isEmpty(waitingPaymentsAmountByCurrency) ||
      waitingPaymentsAmountByCurrency.PLN < overduePaymentsAmount
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t(
          'SETTLEMENT_NOTIFICATION_MESSAGE_SINGLE_INSTALLMENTS_OVERDUE'
        )
      });
    }
    isSingleInstalmentOverdue = true;
  }

  const todayPayment = days.filter((day) => day === Constant.zero);
  if (todayPayment.length === ARRAY_WITH_ONE_ELEMENT_LENGTH) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      overduePaymentsAmount + nextInstallmentAmount >
        waitingPaymentsAmountByCurrency.PLN
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t('SETTLEMENT_NOTIFICATION_MESSAGE_TODAY_PAYMENT')
      });
    }
  }

  const futurePayment = days.filter(
    (day) => day > Constant.zero && day < TWO_WEEKS_PERIOD
  );
  if (
    isEmpty(waitingPaymentsAmountByCurrency) ||
    overduePaymentsAmount + nextInstallmentAmount >
      waitingPaymentsAmountByCurrency.PLN
  ) {
    futurePayment.forEach((day) => {
      notifications.push({
        variant: NotificationVariant.WARNING,
        message: t(
          'SETTLEMENT_NOTIFICATION_MESSAGE_FUTURE_INSTALLMENT_interval',
          { postProcess: 'interval', count: day }
        )
      });
    });
  }

  return {
    notifications,
    isMultipleInstallmentsOverdue,
    isSingleInstalmentOverdue
  };
};

export default getInstallmentsNotifications;
