import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState
} from 'react';

interface IDesktopMenuAnchorContext {
  anchorElNav: HTMLElement | null;
  notificationAnchor: HTMLElement | null;
  setNotificationAnchor: null | Dispatch<SetStateAction<HTMLElement | null>>;
  setAnchorEl: null | Dispatch<SetStateAction<HTMLElement | null>>;
}

export const DesktopMenuAnchorContext =
  createContext<IDesktopMenuAnchorContext>({
    anchorElNav: null,
    notificationAnchor: null,
    setAnchorEl: null,
    setNotificationAnchor: null
  });

export const DesktopMenuAnchorProvider: React.FC<React.ReactNode> = ({
  children
}) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [notificationAnchor, setNotificationAnchor] =
    useState<null | HTMLElement>(null);
  const val = React.useMemo(
    () => ({
      anchorElNav,
      setAnchorEl: setAnchorElNav,
      notificationAnchor,
      setNotificationAnchor
    }),
    [anchorElNav, notificationAnchor]
  );
  return (
    <DesktopMenuAnchorContext.Provider value={val}>
      {children}
    </DesktopMenuAnchorContext.Provider>
  );
};
