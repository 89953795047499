import isEmpty from 'lodash/isEmpty';
import { IPlanData } from 'storage/agreements/agreements.model';
import { PlanStatus } from 'components/Schedule/Schedule.model';
import { WaitingPayments } from './getInstallmentsData';

const getInstallmentsToNotify = (
  planData: IPlanData[],
  overduePaymentsAmount: number,
  waitingPaymentsAmountByCurrency: WaitingPayments
) => {
  const installments: IPlanData[] = [];
  let dateToDisplay = '';
  planData.forEach((installment) => {
    const { status, date } = installment;
    if (
      !isEmpty(waitingPaymentsAmountByCurrency) &&
      waitingPaymentsAmountByCurrency.PLN >= overduePaymentsAmount &&
      status === PlanStatus.CURRENT
    ) {
      installments.push(installment);
      dateToDisplay = date;
    }
    if (
      !isEmpty(waitingPaymentsAmountByCurrency) &&
      waitingPaymentsAmountByCurrency.PLN < overduePaymentsAmount &&
      status === PlanStatus.EXPIRED
    ) {
      installments.push(installment);
      dateToDisplay = date;
    }
    if (
      (status === PlanStatus.CURRENT || status === PlanStatus.EXPIRED) &&
      isEmpty(waitingPaymentsAmountByCurrency)
    ) {
      installments.push(installment);
      dateToDisplay = date;
    }
  });
  return { installments, dateToDisplay };
};
export default getInstallmentsToNotify;
