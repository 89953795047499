import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Url } from 'utils/urls';
import { RootDispatch, RootState } from 'storage/store';
import { Notification } from 'storage/notifications/notifications.model';
import { ExitProtectionModalOptions } from 'pages/Dashboard/pages/Case/pages/Offer/interfaces/ExitModal.model';

function useOutsideAlerter(
  ref: any,
  callback: (args: ExitProtectionModalOptions) => void,
  setMenuVisibility?: Dispatch<SetStateAction<boolean>> | null
) {
  const navigate = useNavigate();
  const { user } = useDispatch<RootDispatch>();
  const notifications = useSelector((state: RootState) => state.notifications);

  const handleClick = (event: Event) => {
    const { target } = event;
    event.preventDefault();
    if ((target as HTMLElement).tagName === 'BUTTON') {
      event.stopPropagation();
      if (setMenuVisibility) setMenuVisibility(false);
      callback({
        isOpen: true,
        func: () => {
          user.logoutUser();
          navigate(Url.login);
        }
      });
    } else {
      const path =
        (target as HTMLAnchorElement).getAttribute('href') ||
        (target as HTMLElement).closest('a[data-link]')!.getAttribute('href');
      let notification: Notification | null = null;
      if (path && path.endsWith('/payment')) {
        const splitPath = path.split('/');
        const [, , , agreementId] = splitPath;
        notification = notifications[Number(agreementId)];
      }

      callback({
        isOpen: true,
        func: () =>
          navigate(path ?? '', {
            state: notification ? notification.paymentState : null
          })
      });
    }
  };

  function handleClickOutside(event: Event) {
    const { target } = event;
    const hasLinkAttribute = (target as HTMLElement).hasAttribute('data-link');
    const isNoAlertLink =
      (target as HTMLElement).hasAttribute('data-link-no-alert') ||
      (target as HTMLElement).hasAttribute('download');
    const isValidLinkElement =
      (target as HTMLElement).tagName === 'A' &&
      (target as HTMLElement).hasAttribute('href');
    const isValidNavigationClick =
      isValidLinkElement ||
      hasLinkAttribute ||
      !!(target as HTMLElement).closest('a[data-link]');

    if (
      ref.current &&
      !ref.current.contains(target) &&
      isValidNavigationClick &&
      !isNoAlertLink
    ) {
      handleClick(event);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, notifications]);
}

export default useOutsideAlerter;
