import styled from 'styled-components/macro';
import theme from 'App.theme';

const NotificationItemWrapper = styled.div`
  width: 100%;
  padding: 20px 25px 25px;

  ${[theme.breakpoints.between('md', 'lg')]} {
    padding: 20px 35px 25px 25px;
  }
  ${[theme.breakpoints.up('md')]} {
    width: 392px;
  }
`;

export default NotificationItemWrapper;
