export const formPart = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  sixth: 6
};

export const Constant = {
  minusOne: -1,
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  fortyFive: 45,
  seventy: 70,
  oneHundred: 100,
  sixHundred: 600,
  sevenHundred: 700,
  oneThousandTwentyFour: 1024,
  threeThousands: 3000,
  tenThousand: 10000,
  tenMillion: 10000000
};

export const Index = {
  minusOne: -1,
  zero: 0,
  first: 1,
  second: 2,
  third: 3,
  fourth: 4
};

export const TimeInMs = {
  oneSecond: 1000,
  twoSeconds: 2000,
  sevenSeconds: 7000,
  thirtySeconds: 30000,
  oneMinute: 60000,
  threeMinutes: 180000,
  fiveMinutes: 300000,
  tenMinutes: 600000,
  fortyFiveMinutes: 2700000,
  oneHour: 216000000
};

export const StatusCode = {
  ok: 200,
  accepted: 202,
  unauthorizedCode: 401,
  notFoundCode: 404,
  badRequestCode: 400,
  forbiddenCode: 403
};

export const SMALL_DEVICE_HEIGHT = 560;

export const INDEX_SINGLE_STEP = 1;
export const INDEX_DOUBLE_STEP = 2;
export const NUMBER_OF_DECIMAL_PLACES = 2;
export const ONE_HUNDRED_PERCENT = 100;
export const EMPTY_ARRAY_LENGTH = 0;
export const ARRAY_WITH_ONE_ELEMENT_LENGTH = 1;
export const ZERO_TIMEOUT = 0;
export const PREVIOUS_PAGE = -1;
export const PRODUCT_TYPE_SETTLEMENT_ZERO = 3;
export const PRODUCT_TYPE_CUD = 5;
export const PRODUCT_TYPE_TOTAL_REPAYMENT = 11;
export const PRODUCT_TYPE_SETTLEMENT_ZERO_PREREQUISITE = 17;
export const PRODUCT_TYPE_DUD = 13;
export const PRODUCT_TYPE_PLUS_SETTLEMENT = 19;
export const SYSTEM_CURRENCY = 'PLN';

export enum Gender {
  F = 'FEMALE',
  M = 'MALE'
}

export enum SettlementPDFName {
  SETTLEMENT_ZERO = 'ugoda_zero',
  DUD = 'dynamiczne_umorzenie',
  PLUS_SETTLEMENT = 'ugoda_na_plus'
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  ACTIVE_OVERDUE = 'ACTIVE_OVERDUE',
  ANNEXED = 'ANNEXED',
  ANNEXED_OVERDUE = 'ANNEXED_OVERDUE',
  FULFILLED = 'FULFILLED'
}

export enum TypeOfAction {
  button = 'button',
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  info = 'info',
  input = 'input',
  link = 'link'
}

export enum BoxState {
  unchecked = 'unchecked'
}

export enum EventType {
  account = 'account',
  offer = 'offer'
}

export enum Product {
  DUD = 'DUD',
  UGODA_0 = 'UGODA_0',
  UGODA_NA_PLUS = 'UGODA_NA_PLUS'
}

export enum IconPosition {
  START = 'start',
  END = 'end'
}

export enum StepType {
  summary = 'podsumowanie',
  pit = 'pit'
}

export enum SettlementAction {
  accept = 'accept',
  reject = 'reject'
}
