import isEmpty from 'lodash/isEmpty';
import { TFunction } from 'react-i18next';
import { Constant } from 'utils/consts';
import getDifferenceInDays from 'utils/getDifferenceInDays';
import { NotificationVariant } from 'components/SettlementNotificationsBox/SettlementNotifications.model';
import { TWO_WEEKS_PERIOD } from './getInstallmentsData/getInstallmentsNotifications';
import { WaitingPayments } from './getInstallmentsData/getInstallmentsData';

const getPayOffNotifications = (
  t: TFunction,
  totalDebtAmount: number,
  waitingPaymentsAmountByCurrency: WaitingPayments,
  payoffDay?: string
) => {
  if (!payoffDay || !totalDebtAmount || !waitingPaymentsAmountByCurrency) {
    return [];
  }
  const days = getDifferenceInDays(payoffDay);
  const notifications = [];
  if (days < Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      (!isEmpty(waitingPaymentsAmountByCurrency) &&
        waitingPaymentsAmountByCurrency.PLN < totalDebtAmount)
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t('SETTLEMENT_NOTIFICATION_PAY_OFF_OVERDUE')
      });
    } else return [];
  }
  if (days === Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      (!isEmpty(waitingPaymentsAmountByCurrency) &&
        waitingPaymentsAmountByCurrency.PLN < totalDebtAmount)
    ) {
      notifications.push({
        variant: NotificationVariant.ERROR,
        message: t('SETTLEMENT_NOTIFICATION_PAY_OFF_TODAY_PAYMENT')
      });
    } else return [];
  }
  if (days < TWO_WEEKS_PERIOD && days > Constant.zero) {
    if (
      isEmpty(waitingPaymentsAmountByCurrency) ||
      (!isEmpty(waitingPaymentsAmountByCurrency) &&
        waitingPaymentsAmountByCurrency?.PLN < totalDebtAmount)
    ) {
      notifications.push({
        variant: NotificationVariant.WARNING,
        message: t('SETTLEMENT_NOTIFICATION_PAY_OFF_FUTURE_interval', {
          postProcess: 'interval',
          count: days
        })
      });
    } else return [];
  }
  return notifications;
};

export default getPayOffNotifications;
