import {
  Agreement,
  ProductTypeEnum,
  SimulationStatusEnum
} from '../storage/agreements/agreements.model';
import getProductParametersValues from '../pages/Dashboard/utils/getProductParametersValues';
import { Constant, ProductStatus } from './consts';

const checkDebtIsPaidOff = (agreement: Agreement) => {
  const { generalData } = agreement;
  const {
    productData,
    totalDebtAmountInSystemCurrency,
    totalDebtAmountWithoutRemissionByCurrency,
    currency
  } = generalData;

  if (productData === null) {
    if (totalDebtAmountInSystemCurrency <= Constant.zero) {
      return {
        isProductDebtPaidOff: true,
        caseOfRepaidProduct: 'noSettlement'
      };
    }
    return { isProductDebtPaidOff: false };
  }

  const { productStatus, productParameters, type, simulationStatus } =
    productData;
  const simulationStatusForCheck =
    simulationStatus === SimulationStatusEnum.WAITING_FOR_SEND_DOCUMENT ||
    simulationStatus ===
      SimulationStatusEnum.WAITING_TO_SATISFY_PREREQUISITES ||
    simulationStatus === SimulationStatusEnum.PREREQUISITES_SATISFIED;
  const { remissionAmount, prerequisitePaymentAmount } =
    getProductParametersValues(productParameters);
  const isProductWithRemission = !!remissionAmount;
  const isTotalDebtPaidOff = !isProductWithRemission
    ? totalDebtAmountInSystemCurrency <= Constant.zero
    : totalDebtAmountWithoutRemissionByCurrency[currency] <= Constant.zero;

  if (type === ProductTypeEnum.HARMONOGRAM) {
    return { isProductDebtPaidOff: false };
  }

  if (
    productStatus === ProductStatus.FULFILLED ||
    (simulationStatus === SimulationStatusEnum.WAITING_FOR_LAUNCH &&
      isTotalDebtPaidOff)
  ) {
    return {
      isProductDebtPaidOff: true,
      caseOfRepaidProduct: 'fulfilled',
      prerequisitePaymentAmount
    };
  }

  if (
    productStatus === null &&
    simulationStatusForCheck &&
    isTotalDebtPaidOff
  ) {
    return {
      isProductDebtPaidOff: true,
      caseOfRepaidProduct: 'simulation',
      prerequisitePaymentAmount
    };
  }

  return { isProductDebtPaidOff: false };
};

export default checkDebtIsPaidOff;
