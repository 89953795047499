import { MouseEvent, useContext } from 'react';
import { ClickAwayListener, Grid, Menu as MuiMenu, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { menuClickEvent } from 'utils/events';
import sendEvent from 'utils/sendEvent';
import { IsLoggedIn } from 'utils/user';
import { Icon } from 'components/Icon';
import { getMenuList } from '../utils';
import { DesktopMenuAnchorContext } from '../utils/desktopMenuAnchorContext';
import { LinkStyled } from '../styled/LinkStyled';
import { UserInfo } from '../styled/UserInfo';
import MenuItem from '../styled/MenuItem';
import AppBarButtons from './AppBarButtons';
import BadgeAndMenu from './Notifications/BadgeAndMenu';

interface IDesktopMenu {
  username: string;
}

const DesktopMenu = ({ username }: IDesktopMenu) => {
  const { t } = useTranslation();

  const { anchorElNav, setAnchorEl } = useContext(DesktopMenuAnchorContext);
  const menuList = getMenuList(t);

  const primaryMenuAction = (text: string) => {
    sendEvent({
      labelText: text,
      ...menuClickEvent
    });
  };

  if (!setAnchorEl) return null;

  const secondaryMenuAction = (text: string) => {
    setAnchorEl(null);
    sendEvent({
      labelText: text,
      ...menuClickEvent
    });
  };

  return (
    <>
      <Grid container alignItems="center" flex="1" marginLeft="68px">
        {menuList.primary.items
          .filter((item) => (IsLoggedIn() ? true : !item.loggedOnly))
          .map((item) => (
            <Grid
              item
              key={item.text}
              sx={{
                fontWeight: 700,
                marginRight: '69px'
              }}
            >
              <LinkStyled
                $color="primary"
                to={item.href}
                onClick={() => primaryMenuAction(item.text)}
              >
                {item.text}
              </LinkStyled>
            </Grid>
          ))}
      </Grid>
      {IsLoggedIn() ? (
        <Grid item pr={5}>
          <BadgeAndMenu />
        </Grid>
      ) : null}
      <Grid item display="flex">
        {username && (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Grid
              container
              alignItems="center"
              marginRight="40px"
              sx={{ cursor: 'pointer' }}
              onClick={(event: MouseEvent<HTMLElement>) =>
                setAnchorEl(anchorElNav ? null : event.currentTarget)
              }
            >
              <UserInfo
                variant="body2"
                color="secondary"
                sx={{ marginRight: '10px' }}
              >
                <Icon icon="user" color="secondary" />
                {username}
              </UserInfo>
              <Icon icon="chevronDown" fontSize="12px" color="secondary" />
            </Grid>
          </ClickAwayListener>
        )}
        <MuiMenu
          anchorEl={anchorElNav}
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={() => setAnchorEl(null)}
          sx={{
            display: { xs: 'none', lg: 'block' },
            padding: '10px',
            marginTop: '22px',
            zIndex: 1300
          }}
          TransitionComponent={Slide}
          disableAutoFocusItem
          disableScrollLock
        >
          {menuList.secondary.items.map((item) => (
            <MenuItem
              onClick={() => secondaryMenuAction(item.text)}
              key={item.text}
            >
              <LinkStyled to={item.href}>{item.text}</LinkStyled>
            </MenuItem>
          ))}
        </MuiMenu>
        <Grid item>
          <AppBarButtons forDesktop />
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopMenu;
